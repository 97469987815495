import React from "react"
import FAQs from "../../../../components/body/pages/en-gb/faq"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

const FAQSPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-gb/legal/faq"}
      title="Frequently Asked Questions | Kuda | The Money App for Africans"
      description="You've got questions and we've got answers, find answers to our most frequently asked questions here. You can also find these questions in the app."
    />
    <FAQs />
  </Layout>
)

export default FAQSPage
